import { useStaticQuery, graphql, Link } from "gatsby"
import React from "react"
import { getValueD9, renameImage } from "../../utils"

const Footer = ({ node, location }) => {
  const data = useStaticQuery(graphql`
    query allPageFooter {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)
  const existingPaths = data.allSitePage.nodes.map(el => el.path)
  const menuItems = getValueD9(node, "field_menu", [])
  const menu = menuItems.filter(
    menuItem =>
      existingPaths.indexOf(getValueD9(menuItem, "uri", "/").replace("internal:", "")) > -1 ||
      existingPaths.indexOf(getValueD9(menuItem, "uri", "/").replace("internal:", "") + "/") > -1
  )
  return (
    <footer className="uk-section uk-section-secondary">
      <div className="uk-container">
        <div uk-grid="" className="uk-grid">
          <div className="uk-width-1-6@s uk-flex uk-flex-top uk-flex-center uk-pd">
            <img
              src={renameImage(
                getValueD9(
                  node,
                  "relationships.field_logo_footer.relationships.field_media_image.uri.value",
                  ""
                )
              )}
              alt={node.title}
              className="uk-width-1-2 uk-width-1-1@s"
            />
          </div>
          <div className="uk-width-1-4@s menu-list">
            <ul className="uk-list">
              {menu.map((menuItem, index) => {
                const path = getValueD9(menuItem, "uri", "/").replace("internal:", "")
                const isActive = location.pathname.indexOf(path) === 0
                return (
                  <li key={"file-" + index}>
                    <Link
                      activeClassName="uk-active"
                      className={
                        isActive
                          ? "uk-text-capitalize uk-text-muted uk-active"
                          : "uk-text-capitalize uk-text-muted"
                      }
                      to={getValueD9(menuItem, "uri", "/").replace("internal:", "")}
                    >
                      {getValueD9(menuItem, "title", "")}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="uk-width-1-3@s">
            <p>
              <span
                className="uk-icon uk-icon-image uk-margin-small-right"
                style={{ backgroundImage: "url(/svg/location-outline.svg)" }}
              ></span>
              <span className="uk-text-muted">
                {getValueD9(node, "field_address_text.processed", "").replace(/<[^>]*>?/gm, "")}
              </span>
            </p>
            <p>
              <Link to="/contact">
                <span
                  className="uk-icon uk-icon-image uk-margin-small-right"
                  style={{ backgroundImage: "url(/svg/mail.svg)" }}
                ></span>
                <span className="uk-text-muted">{getValueD9(node, "field_email", "")}</span>
              </Link>
            </p>
            <p>
              <a href={"tel:+687" + getValueD9(node, "field_phone", "")}>
                <span
                  className="uk-icon uk-icon-image uk-margin-small-right"
                  style={{ backgroundImage: "url(/svg/phone.svg)" }}
                ></span>
                <span className="uk-text-muted">
                  (+687) {("" + getValueD9(node, "field_phone", "")).match(/.{1,2}/g).join(" ")}
                </span>
              </a>
            </p>
          </div>
          <div className="uk-width-1-6@s uk-flex uk-flex-top uk-flex-center">
            <a href="https://province-nord.nc" target="_blank">
              <img src="/logofooter.png" alt={node.label} className="uk-width-1-2 uk-width-1-1@s" />
            </a>
          </div>
          <div className="uk-width-1-1 uk-text-right uk-margin-large-top">
            <span className="uk-text-small uk-text-muted">
              Conception et réalisation :{` `}
              <a className="uk-text-muted" href="https://www.la-fabrik.nc">
                La Fabrik
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
