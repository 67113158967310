export const getPath = url => {
  url = url || ""
  return url.replace(/^((?:https?:)|(?:public?:))?(?:\/\/?)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/g, "")
}

export const getValueD9 = (node, fieldName, defaultValue = false) => {
  const fieldNameHierarchy = fieldName.split(".")
  var output = { ...node }
  fieldNameHierarchy.forEach(prop => {
    if (typeof output === "object") {
      if (output[prop]) {
        output = output[prop]
        return
      }
    }
    output = false
  })
  return output ? output : defaultValue
}

export const sort = (items, keys, order = "ASC") => {
  return items.sort((a, b) => {
    let valA = getValueD9(a, keys)
    let valB = getValueD9(b, keys)
    valA = typeof valA === "string" ? valA.toUpperCase() : valA
    valB = typeof valB === "string" ? valB.toUpperCase() : valB

    if (valA > valB) {
      return order === "ASC" ? 1 : -1
    }
    if (valA < valB) {
      return order === "ASC" ? -1 : 1
    }
    return 0
  })
}

export const getValue = (node, fieldName, type, defaultValue) => {
  type = type || false
  node[fieldName] = node[fieldName] === null ? (node[fieldName] = { und: null }) : node[fieldName]
  if (typeof defaultValue === "undefined") {
    defaultValue = false
  }
  if (typeof node !== "object") {
    return defaultValue
  }
  if (!node.hasOwnProperty(fieldName)) {
    return defaultValue
  }

  if (typeof node[fieldName] === "undefined") {
    return defaultValue
  }
  if (type === false) {
    return node[fieldName].und === null || node[fieldName].length === 0
      ? defaultValue
      : node[fieldName].und[0]
  }
  if (typeof type === "string") {
    if (type === "Raw") {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und
    }
    if (type === "Phone") {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : formatPhone(node[fieldName].und[0])
    }
    const types = type.split(".")
    if (types.length === 1) {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]]
    }
    if (types.length === 2) {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]] === null || node[fieldName].und[0][types[0]].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]]
    }
    if (types.length === 3) {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]] === null || node[fieldName].und[0][types[0]].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]] === null ||
          node[fieldName].und[0][types[0]][types[1]].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]][types[2]]
    }
    if (types.length === 4) {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]][types[2]][types[3]]
    }
  }
}

export const renameImage = (imgLink, defaultImg, style) => {
  imgLink = imgLink || false
  defaultImg = defaultImg || false
  // style = style || "photo_galerie_paysage"
  style = style || false
  style = false
  const path = style ? "/files/styles/" + style + "/public/" : "/sites/default/files/"
  if (!imgLink) {
    return defaultImg
  }
  return imgLink.replace("public://", path)
}

export const getValidUrl = (url = "") => {
  let newUrl = typeof window !== "undefined" ? window.decodeURIComponent(url) : url
  newUrl = newUrl.trim().replace(/\s/g, "")

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`
  }

  return newUrl
}

export const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export const stripHtml = s => {
  s = typeof s === "string" ? s : ""
  return s.replace(/(\r\n|\n|\r)/gm, "").replace(/(<([^>]+)>)/gi, "")
}

export const limitText = (s, length) => {
  s = s || ""
  s = stripHtml(s)
  return s.replace(new RegExp("^(.{" + length + "}[^s]*).*"), "$1")
}

export const cleanPath = path_alias => {
  return path_alias.replace("sous-site/", "")
}

export const getDescription = string => {
  return (
    stripHtml(string)
      .replace(/&nbsp;/g, " ")
      .substring(0, 160) + "..."
  )
}
