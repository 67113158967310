import React from "react"
import { Link } from "gatsby"
const Breadcrumbs = ({ crumbs, title }) => {
  const filteredCrumbs = crumbs.filter(crumb => crumb.pathname !== "/page")
  if (crumbs.length > 1) {
    return (
      <ul className="uk-breadcrumb uk-margin-top">
        {filteredCrumbs.map((crumb, i) => {
          if (i + 1 === filteredCrumbs.length) {
            crumb.crumbLabel = title
            return (
              <li key={i}>
                <span className="uk-text-bold uk-text-emphasis">{crumb.crumbLabel}</span>
              </li>
            )
          }
          return (
            <li key={i}>
              <Link to={crumb.pathname}>{crumb.crumbLabel}</Link>
            </li>
          )
        })}
      </ul>
    )
  } else {
    return <></>
  }
}

export default Breadcrumbs
