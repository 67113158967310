import React from "react"
import HTMLReactParser from "html-react-parser"
import { getValueD9, renameImage } from "../utils"
const Slider = ({ sliderData }) => {
  sliderData = sliderData || false
  if (sliderData) {
    return (
      <div
        className="uk-position-relative"
        uk-slideshow="min-height: 300; max-height: 600;autoplay: true;autoplay-interval: 6000"
      >
        <ul className="uk-slideshow-items">
          {sliderData.map((slider, i) => {
            var img = getValueD9(
              slider,
              "relationships.field_cover.relationships.field_media_image.uri.value",
              false
            )
            if (img) {
              return (
                <li key={i}>
                  <img src={renameImage(img, null, "photo_galerie_paysage")} alt="" uk-cover="" />
                  <div className="uk-position-center uk-position-small uk-hidden">
                    <h2>{HTMLReactParser(getValueD9(slider, "field_title", ""))}</h2>
                    {HTMLReactParser(getValueD9(slider, "field_subtitle", ""))}
                  </div>
                </li>
              )
            }
            return <></>
          })}
        </ul>
        <div className="uk-position-bottom-center uk-position-small">
          <ul className="uk-dotnav">
            {sliderData.map((slider, i) => {
              var img = getValueD9(
                slider,
                "relationships.field_cover.relationships.field_media_image.uri.value",
                false
              )
              if (img) {
                return (
                  <li key={i} uk-slideshow-item={i}>
                    <a href="#">{getValueD9(slider, "field_title", "")}</a>
                  </li>
                )
              }
              return <></>
            })}
          </ul>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default Slider
