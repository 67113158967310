import React, { useState, useEffect } from "react"

function loadGTM(my_GTM_id) {
  if (typeof window !== "undefined") {
    if (typeof dataLayer !== "undefined") {
      dataLayer.push({ event: "pageview" })
    } else {
      //you can add checks here to see if GDPR got accepted if you want...
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : ""
        j.async = true
        j.src = "https://www.googletagmanager.com/gtag.js?id=" + i + dl
        j.onload = function () {
          if (typeof dataLayer !== "undefined") {
            function gtag() {
              dataLayer.push(arguments)
            }
            gtag("js", new Date())
            gtag("config", i)
            dataLayer.push({ event: "pageview" })
          }
        }
        f.parentNode.insertBefore(j, f)

        // console.log(my_GTM_id, "test")
      })(window, document, "script", "dataLayer", my_GTM_id)
    }
  }
}

function loadMATOMO(siteid) {

  if (typeof window !== "undefined") {

      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="https://provincenordnc.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '7']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='//cdn.matomo.cloud/provincenordnc.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
      })();

  }
}

function getCookie(cname, defaultValue) {
  var name = cname + "="
  var decodedCookie = decodeURIComponent(document.cookie) || ""
  var ca = typeof decodedCookie === "string" ? decodedCookie.split(";") : false
  if (ca) {
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
  }
  return defaultValue
}
function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

const CookiesBanner = () => {
  const gacode = "G-N67G77P7PZ"
  const siteid = "7"
  // const location = useLocation();
  const [onboarding, setOnboarding] = useState(undefined)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined" && typeof onboarding !== "undefined") {
      setCookie("onboarding", onboarding, 365)
      if (onboarding) {
        // console.log(gacode, "test")
        // loadGTM(gacode)
        loadMATOMO(siteid)
      }
    }
  }, [onboarding])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onboardingTemp = getCookie("onboarding", undefined)
      setOnboarding(onboardingTemp)
      setLoaded(true)
    }
  }, [])
  return (
    <div
      className="uk-box-shadow-medium"
      id="cookie-banner"
      style={{
        transform:
          typeof onboarding === "undefined" && loaded && gacode
            ? "translate(0px,0%)"
            : "translate(0px,150%)",
      }}
    >
      <div>
        <div className="uk-container">
          <div className="uk-flex uk-flex-middle uk-grid uk-grid-row-medium">
            <div className="uk-width-2d-3@m uk-width-expand@m uk-grid-margin">
              <p className="uk-margin-remove">
                En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies
                pour nous permettre de réaliser des statistiques de visites.
              </p>
            </div>
            <div className="uk-width-1-s3@m uk-width-auto@m uk-grid-margin">
              <div className="uk-flex uk-flex-center">
                <div className="uk-text-center">
                  <button
                    className="uk-button uk-button-primary"
                    onClick={() => setOnboarding(true)}
                  >
                    J&#39;accepte
                  </button>
                </div>
                <div className="uk-text-center uk-margin-left">
                  <button
                    className="uk-button uk-button-default "
                    onClick={() => setOnboarding(false)}
                  >
                    Refuser
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookiesBanner
