import React from "react"
import { getValueD9 } from "../../utils"

const Offcanvas = ({ menu, location }) => {
  return (
    <div id="offcanvas-nav" uk-offcanvas="flip: true;overlay: true">
      <div className="uk-offcanvas-bar">
        <button className="uk-offcanvas-close" type="button" uk-close=""></button>
        <ul className="uk-margin-large-top uk-nav uk-nav-primary uk-nav-center side-bar-nav">
          {menu.map((menuItem, index) => {
            const path = getValueD9(menuItem, "uri", "/").replace("internal:", "")
            const isActive = location.pathname.indexOf(path) === 0
            return (
              <li
                key={"file-" + index}
                className={getValueD9(menuItem, "title", "").replace(/\s+/g, "-").toLowerCase()}
              >
                <a
                  className={
                    isActive
                      ? "uk-text-bold uk-text-primary uk-active"
                      : "uk-text-bold uk-text-primary"
                  }
                  href={getValueD9(menuItem, "uri", "/").replace("internal:", "")}
                >
                  {getValueD9(menuItem, "title", "")}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Offcanvas
