/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../seo"
import Header from "./header"
import Footer from "./Footer"
import "./uikit.scss"
import { getValueD9 } from "../../utils"
import Slider from "../Slider"
import Breadcrumbs from "../Breadcrumbs"
import EventsBlock from "../EventsBlock"
import HTMLReactParser from "html-react-parser"
import CookiesBanner from "./CookiesBanner"
const Layout = ({
  children,
  title,
  wide,
  sliderData,
  hideBlocks,
  pageContext,
  location,
  description,
}) => {
  sliderData = sliderData || false
  hideBlocks = hideBlocks || false
  pageContext = pageContext || {}
  pageContext.breadcrumb = pageContext.breadcrumb || { crumbs: [] }
  const {
    breadcrumb: { crumbs },
  } = pageContext
  useEffect(() => {
    if (typeof window !== "undefined") {
      const uikit = require("uikit/dist/js/uikit.min")
      const icons = require("uikit/dist/js/uikit-icons.min")
      uikit.use(icons)
    }
  }, [])
  title = title || false
  wide = wide || false
  const data = useStaticQuery(graphql`
    {
      groupSousSite {
        id
        label
        field_email
        field_color {
          color
        }
        field_address_text {
          value
          processed
          format
        }
        field_subtitle
        field_phone
        relationships {
          field_logo {
            relationships {
              field_media_image {
                url
                uri {
                  url
                  value
                }
              }
            }
          }
          field_logo_footer {
            relationships {
              field_media_image {
                url
                uri {
                  url
                  value
                }
              }
            }
          }
          field_block {
            field_body {
              format
              value
              processed
            }
            field_title
          }
        }
        field_menu {
          title
          uri
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.groupSousSite.label || `Title`}
        logo={getValueD9(
          data.groupSousSite,
          "relationships.field_logo.relationships.field_media_image.uri.value",
          false
        )}
        menu={getValueD9(data.groupSousSite, "field_menu", [])}
        subtitle={getValueD9(data.groupSousSite, "field_subtitle", false)}
        location={location}
      />

      <Slider sliderData={sliderData} />
      <div className="uk-background-muted">
        <div className={wide ? "" : "uk-container"}>
          <Breadcrumbs crumbs={crumbs} title={title} />
          <main className="uk-section">
            {title !== false && (
              <>
                <SEO title={title} description={description} />
                <h1 className="uk-text-primary">{title}</h1>
              </>
            )}
            <div uk-grid="" className="uk-grid">
              <div
                className={
                  getValueD9(data.groupSousSite, "relationships.field_block") && !hideBlocks
                    ? "uk-width-2-3@m content"
                    : "uk-width-1 content"
                }
              >
                {children}
              </div>
              {getValueD9(data.groupSousSite, "relationships.field_block") && !hideBlocks && (
                <div className="uk-width-1-3@m">
                  {getValueD9(data.groupSousSite, "relationships.field_block", []).map(
                    (block, i) => (
                      <React.Fragment key={i}>
                        {i === 1 && <EventsBlock limit={3} />}
                        <div key={i} className="uk-card uk-margin uk-card-primary uk-card-body">
                          <h3 className="uk-h2">
                            {getValueD9(block, "field_title.processed", false)}
                          </h3>
                          {HTMLReactParser(getValueD9(block, "field_body.processed", ""))}
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      <Footer node={data.groupSousSite} location={location} />
      <CookiesBanner />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
