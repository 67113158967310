import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { getValueD9, renameImage } from "../../utils"
import Offcanvas from "./Offcanvas"
const Header = ({ siteTitle, logo, menu, subtitle, location }) => {
  const data = useStaticQuery(graphql`
    query allPage {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)
  const existingPaths = data.allSitePage.nodes.map(el => el.path)
  menu = menu.filter(
    menuItem =>
      existingPaths.indexOf(getValueD9(menuItem, "uri", "/").replace("internal:", "")) > -1 ||
      existingPaths.indexOf(getValueD9(menuItem, "uri", "/").replace("internal:", "") + "/") > -1
  )
  return (
    <>
      <div className="uk-container">
        <nav className="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar="">
          <div className="uk-navbar-left">
            <div className="uk-navbar-item uk-padding">
              {logo && (
                <div className="uk-logo">
                  <Link to="/" title={siteTitle}>
                    <img src={renameImage(logo)} alt={siteTitle} />{" "}
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="uk-navbar-right">
            <span className="uk-h3 uk-text-primary uk-visible@m">{subtitle}</span>
            <a
              className="uk-navbar-toggle uk-navbar-item uk-hidden@m"
              uk-toggle=""
              uk-navbar-toggle-icon=""
              href="#offcanvas-nav"
            ></a>
          </div>
        </nav>
      </div>
      <header
        className="nav uk-background-primary uk-light bg-motif uk-visible@m"
        data-uk-sticky="cls-active:  uk-box-shadow-medium;  animation: uk-animation-slide-top"
      >
        <div className="uk-container">
          <nav className="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar="">
            <div className="uk-navbar-left">
              <ul className="uk-navbar-nav main-menu">
                {menu.map((menuItem, index) => {
                  const path = getValueD9(menuItem, "uri", "/").replace("internal:", "")
                  const isActive = location.pathname.indexOf(path) === 0
                  return (
                    <li
                      key={"file-" + index}
                      className={
                        (index > 0 ? "nav-item-border-left" : "") +
                        " " +
                        getValueD9(menuItem, "title", "").replace(/\s+/g, "-").toLowerCase()
                      }
                    >
                      <Link
                        activeClassName="uk-active"
                        className={isActive ? "uk-text-bold uk-active" : "uk-text-bold"}
                        to={getValueD9(menuItem, "uri", "/").replace("internal:", "")}
                      >
                        {getValueD9(menuItem, "title", "")}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <Offcanvas menu={menu} location={location} />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
