import React from "react"
import { getValueD9, limitText, capitalize, cleanPath } from "../utils"
import { useStaticQuery, graphql, Link } from "gatsby"
import moment from "moment"
import "moment/locale/fr"
moment.locale("fr")

const EventsBlock = ({ limit }) => {
  limit = limit || false
  const data = useStaticQuery(graphql`
    {
      groupSousSite(
        relationships: {
          group_content__sous_site_group_node_ss_event: {
            elemMatch: { relationships: { entity_id: { status: { eq: true } } } }
          }
        }
      ) {
        id
        label
        relationships {
          group_content__sous_site_group_node_ss_event {
            relationships {
              entity_id {
                path {
                  alias
                }
                body {
                  processed
                  summary
                }
                title
                relationships {
                  field_cover {
                    relationships {
                      field_media_image {
                        url
                        uri {
                          url
                          value
                        }
                      }
                    }
                  }
                  field_event_collection {
                    field_dates {
                      end_value
                      value
                    }
                    relationships {
                      field_commune {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const items = getValueD9(
    data,
    "groupSousSite.relationships.group_content__sous_site_group_node_ss_event",
    []
  )
  const eventList = items.filter(node => {
    return (
      moment(
        getValueD9(
          node,
          "relationships.entity_id.relationships.field_event_collection.0.field_dates.value"
        )
      ).format("X") > moment().format("X")
    )
  })
  return (
    <div className="uk-card uk-card-primary uk-margin uk-card-body">
      <h3 className="uk-h2">Prochains événements</h3>
      {eventList.length > 0 && (
        <>
          {eventList
            .filter((node, i) => limit && i < limit)
            .map((node, i) => {
              const date = moment(
                getValueD9(
                  node,
                  "relationships.entity_id.relationships.field_event_collection.0.field_dates.value"
                )
              )

              return (
                <Link
                  key={i}
                  to={cleanPath(getValueD9(node, "relationships.entity_id.path.alias", "/"))}
                  className="uk-display-block uk-link-toggle uk-margin-small"
                >
                  <div className="uk-flex-middle uk-grid" uk-grid="">
                    <div className="uk-width-1-4">
                      <div className="event-date uk-text-center">
                        <p className="uk-text-primary uk-text-small uk-margin-bottom-remove">
                          {capitalize(date.format("dddd"))}
                        </p>
                        <p className="uk-h3 uk-text-default">{date.format("DD")}</p>
                      </div>
                    </div>
                    <div className="uk-width-3-4">
                      <p className="uk-margin-remove uk-text-bold">
                        {getValueD9(node, "relationships.entity_id.title", "value", "")}
                      </p>
                      <p className="uk-margin-remove">
                        {limitText(
                          getValueD9(node, "relationships.entity_id.body.processed", "").replace(
                            /&nbsp;/g,
                            ""
                          ),
                          10
                        )}
                      </p>
                    </div>
                  </div>
                </Link>
              )
            })}
        </>
      )}
      {eventList.length === 0 && <p>Pas d'événements prévus</p>}
    </div>
  )
}

export default EventsBlock
